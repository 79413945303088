// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import React from 'react';

export async function getRoutes() {
  const routes = {"1":{"id":"1"},"2":{"path":"/*","redirect":"/","parentId":"1","id":"2"},"3":{"path":"/","parentId":"1","id":"3"}} as const;
  return {
    routes,
    routeComponents: {
'1': React.lazy(() => import(/* webpackChunkName: "layouts__sandbox__sandbox" */'@/layouts/sandbox/sandbox.jsx')),
'2': React.lazy(() => import('./EmptyRoute')),
'3': React.lazy(() => import(/* webpackChunkName: "p__landing__index" */'@/pages/landing/index.js')),
},
  };
}
